<template>
  <div>
    <b-row class="justify-content-between mb-2">
      <b-col
        cols="12"
        md="4"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <label>Show</label>
        <v-select
          @on-change="paginateChange"
          :options="perPageOptions"
          v-model="search.paginate"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
        />
        <label>entries</label>
      </b-col>

      <!-- Search -->
    </b-row>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col md="3">
            <Select
              v-model="search.status"
              filterable
              @on-change="SearchData"
              :clearable="true"
              placeholder="Status"
            >
              <Option :value="1">Active</Option>
              <Option :value="2">Deactive</Option>
            </Select>
          </b-col>
          <b-col md="3">
            <DatePicker
              format="yyyy-MM-dd"
              type="date"
              name="from_date"
              v-model="starting_date"
              id="from_date"
              placeholder="Select From Date"
              :clearable="true"
              style="width: 100%"
            ></DatePicker>
          </b-col>
          <b-col md="3">
            <DatePicker
              format="yyyy-MM-dd"
              type="date"
              name="to_date"
              v-model="ending_date"
              placeholder="Select To Date"
              :clearable="true"
              style="width: 100%"
            ></DatePicker>
          </b-col>
          <b-col md="3">
            <button class="btn btn-primary" @click="exportLeads()">
              Export Leads
            </button>
          </b-col>
        </b-row>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th class="align-middle text-center">Sl</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Location</th>
              <th>Profession</th>
              <th>Interested Course</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lead, index) in leads.data" :key="index">
              <td class="align-middle text-center">
                <p>{{ leads.from + index }}</p>
              </td>

              <td>
                <p class="mb-0">
                  <b>{{ lead.name }}</b>
                </p>
              </td>
              <td>
                <p class="mb-0">
                  <b>{{ lead.number }} </b>
                </p>
              </td>
              <td>
                <p class="mb-0">
                  <b>{{ lead.email }} </b>
                </p>
              </td>
              <td>
                <p class="mb-0">
                  <b>{{ lead.location }} </b>
                </p>
              </td>
              <td>
                <p class="mb-0">
                  <b>{{ lead.profession }}</b>
                </p>
              </td>
              <td>
                <p class="mb-0">
                  <b>{{ lead.course.name }}</b>
                </p>
              </td>
              <td>
                <p class="mb-0">
                  <b>{{ lead.created_at }}</b>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ leads.from ? leads.from : 0 }} to
              {{ leads.to ? leads.to : 0 }} of
              {{ leads.total ? leads.total : 0 }}
              entries</span
            >
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <pagination
              :data="leads"
              @pagination-change-page="getResults"
              align="right"
              :show-disabled="true"
              :limit="5"
            ></pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  data() {
    return {
      search: {
        status: null,
        from_date: "",
        to_date: "",
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      leads: {},
      starting_date: null,
      ending_date: null,
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      this.search.from_date = this.formatDate(this.starting_date);
      this.search.to_date = this.formatDate(this.ending_date);
      const leads = await this.callApi(
        "post",
        "/app/student/get/discount/leads?page=" + this.search.page,
        this.search
      );
      if (leads.status == 200) {
        this.leads = leads.data;
      }
    },

    exportLeads() {
      axios
        .post("/app/student/export/discount/leads", this.search, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "discount_lead.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    formatDate(date) {
      if (date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
      return null;
    },
  },
  watch: {
    starting_date: function (val) {
      this.SearchData();
    },
    ending_date: function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
  },
  components: {
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
};
</script>
